@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: theme("fontFamily.mulish");
  }
}

@layer components {
  .select-input {
    box-sizing: border-box;
  }
  .select-input div {
    height: 100% !important;
    border: 1px solid theme("colors.gray.1") !important;
    border-radius: 5px !important;
  }

  .select-input span {
    display: flex;
    align-items: center;
  }

  .number-input {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #c4c4c4;
  }

  .number-input div {
    height: 100%;
    height: 100% !important;
  }

  .number-input input {
    height: 100% !important;
    font-size: 16px;
  }

  .ant-select-selector {
    background: rgba(206, 55, 92, 0.1) !important;
    border: none !important;
  }

  .list-check {
    list-style-image: url("./assets/icons/check.svg");
  }

  .response-slider {
    flex: 1 1;
    margin: 0 0 22px 10px;
  }

  .response-slider .ant-slider-rail {
    height: 15px;
    border-radius: 10px;
  }

  .response-slider .ant-slider-handle {
    width: 15px;
    height: 35px;
    background: #222222;
    border-radius: 10px;
    border: unset;
    margin-top: -11px;
  }

  .response-slider .ant-slider-mark-text {
    top: 10px;
  }

  .response-slider .ant-slider-track {
    position: absolute;
    height: 15px;
    background-color: var(--danger);
    border-radius: 10px;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }

  .response-slider .ant-slider-track:hover {
    background-color: var(--danger);
  }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-weight: 700;
  font-size: 16px;
  line-height: 128%;
  color: #1a141f;
}

.ant-collapse-borderless {
  background-color: #fff;
}

.ant-collapse-borderless > .ant-collapse-item {
  padding: 15px 0;
}

/*.ant-slider-rail {
  height: 10px;
  border-radius: 20px;
} */

.ant-input:focus,
.ant-input-focused {
  box-shadow: none !important;
  border-color: none !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: transparent !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.slick-list {
  height: auto;
  padding-bottom: 100px;
  margin-top: 50px;
  width: 100%;
}

@import "~antd/dist/antd.css";
